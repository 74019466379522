import React from "react";
import styles from "./home.module.css";
import Container from "../../components/container/container";
import SEO from "../../components/seo/seo";
import homeImage from "../../assets/images/home.png";
import Layout from "../../components/layout/layout";
import homeImageSp from "../../assets/images/home-sp@3x.png";

const Home = () => {
	return (
		<Layout page="home">
			<SEO />
			<section className={styles.wrapper}>
				<Container>
					<div className={styles.inner}>
						<div className={styles.content}>
							<div className={styles.title}>
								<span className={styles.titleSmall}>
									Coming Soon:
								</span>
								<h1>
									A Top-Notch Essay Writing Service for Your
									Needs
								</h1>
							</div>
							<div className={styles.paragraph}>
								<p>
									Ever sat up late, struggling to write your
									essays, and wondered how wonderful it would
									be to have a trusted essay writing service
									at your beck and call? Your wish is going to
									come true.
								</p>
								<p>
									We are launching an online essay writing
									service soon to offer all the professional
									help you need with your essays and writing
									assignments. A premium writing service, our
									essay writers will be handpicked from
									leading universities across the US, UK,
									Canada, and Australia.
								</p>
								<p>
									When you hire a writer from us, you can be
									sure to receive custom papers that are
									plagiarism-free. Our service will be quick
									and reliable, helping you get the utmost
									peace of mind
								</p>
							</div>
						</div>
					</div>
				</Container>
				<div className={styles.contentLeft}>
					<div className={styles.innerLeft}>
						<p className={styles.caption}>
							From college and university to Master and Doctoral,
							our writers will be equipped to help you with:
						</p>

						<ul className={styles.list}>
							<li>
								<span>Academic essays</span>
							</li>
							<li>
								<span>Research papers</span>
							</li>
							<li>
								<span>Projects</span>
							</li>
							<li>
								<span>Business plans</span>
							</li>
							<li>
								<span>Marketing plans</span>
							</li>
							<li>
								<span>Theses</span>
							</li>
							<li>
								<span>Reports</span>
							</li>
						</ul>
						<div className={styles.image}>
							<img
								className={styles.imgPC}
								src={homeImage}
								alt="From college and university to Master and Doctoral,
								our writers will be equipped to help you with:"
							/>
							<img
								className={styles.imgSP}
								src={homeImageSp}
								alt="From college and university to Master and Doctoral,
								our writers will be equipped to help you with:"
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Home;
