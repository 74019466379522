import React from "react";
import styles from "./about-author.module.css";
import avata from "../../../assets/images/author.png";
import iconFaceBook from "../../../assets/images/icon-facebook-gray.svg";
import iconlinkedin from "../../../assets/images/icon-linkedin-gray.svg";
import iconTwitter from "../../../assets/images/icon-twitter-gray.svg";
import iconWebsite from "../../../assets/images/icon-website-gray.svg";

const AboutAuthor = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.author}>
				<div className={styles.authorInner}>
					<a
						className={styles.profile}
						href="https://adelabelin.com/"
						rel="noreferrer"
						target="_blank"
					>
						<img src={avata} alt="avata"/>
					</a>
					<div className={styles.name}>
						by
						<a
							className={styles.link}
							href="https://adelabelin.com/"
							rel="noreferrer"
							target="_blank"
						>
							Adela Belin
						</a>
					</div>
				</div>
				<div className={styles.social}>
					<ul className={styles.socialList}>
						<li className={styles.socialItem}>
							<a
								href="https://www.facebook.com/adelahelenka.belin"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={iconFaceBook}
									alt="Adela Belin Facebook"
								/>
							</a>
						</li>
						<li className={styles.socialItem}>
							<a
								href="https://www.linkedin.com/in/adelabelin/"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={iconlinkedin}
									alt="Adela Belin Linkedin"
								/>
							</a>
						</li>
						<li className={styles.socialItem}>
							<a
								href="https://twitter.com/AdelaBelin"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={iconTwitter}
									alt="Adela Belin Twitter"
								/>
							</a>
						</li>
						<li className={styles.socialItem}>
							<a
								href="https://adelabelin.com/"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={iconWebsite}
									alt="Adela Belin Twitter"
								/>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div className={styles.info}>
				<p className={styles.intro}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco.
				</p>
			</div>
		</div>
	);
};

export default AboutAuthor;
