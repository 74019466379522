import React from "react";
import Layout from "../../components/layout/layout";
import styles from "./blog-post.module.css";
import Category from "../blog/category/category";
import AboutAuthor from "./about-author/about-author";
import ButtonBack from "../../components/button-back/button-back";
import Container from "../../components/container/container";
import ContainerSmall from "../../components/container/container-small";
import Info from "./info-date/post-info";
import RelatedPost from "./related-post/related-post";
import SEO from "../../components/seo/seo";
import PageTitleWrapper from "../../components/page-title-wrapper/page-title-wrapper";
import bgPage from "../../assets/images/bg-post.png";

const BlogPost = () => {
	return (
		<Layout page="blog-post">
			<SEO/>
			<PageTitleWrapper bgImage={bgPage}>
				<div className={styles.category}>
					<Category link="#" title="Name of Category"/>
				</div>
				<h1 className={styles.title}>
					Tentang Creativity Block pada UI Designer
				</h1>
				<div className={styles.date}>June 07, 2021</div>
			</PageTitleWrapper>
			<div className={styles.wrapper}>
				<div className={styles.inner}>
					<ContainerSmall>
						<div className={styles.content}>
							<p>
								Suspendisse potenti. Praesent lorem odio,
								vehicula ultrices convallis in, convallis in mi.
								Duis vel mattis eros. Morbi eu urna dolor. Etiam
								mollis molestie justo. Sed aliquet ultrices
								metus, in pretium lacus egestas eget. Morbi
								convallis aliquam ante nec vulputate. In a
								volutpat massa, et interdum dolor. Duis
								consequat elit in risus volutpat, sit amet
								molestie ex accumsan. Suspendisse potenti.
								Praesent lorem odio, vehicula ultrices convallis
								in, convallis in mi. Duis vel mattis eros. Morbi
								eu urna dolor. Etiam mollis molestie justo. Sed
								aliquet ultrices metus, in pretium lacus egestas
								eget. Morbi convallis aliquam ante nec
								vulputate. In a volutpat massa, et interdum
								dolor. Duis consequat elit in risus volutpat,
								sit amet molestie ex accumsan. sit amet molestie
								ex accumsan.
							</p>
							<div className={styles.paddingBox}>
								<ul>
									<li>Suspendisse potenti.</li>
									<li>Praesent lorem odio.</li>
									<li>Vehicula ultrices convallis in.</li>
									<li>Duis vel mattis eros.</li>
								</ul>
								<div className={styles.highLight}>
									Suspendisse potenti. Praesent lorem odio,
									vehicula ultrices convallis in, convallis in
									mi. Duis vel mattis eros. Morbi eu urna
									dolor. Etiam mollis molestie justo.
								</div>
							</div>
							<h3>Subtitle example right here</h3>
							<p>
								Suspendisse potenti. Praesent lorem odio,
								vehicula ultrices convallis in, convallis in mi.
								Duis vel mattis eros. Morbi eu urna dolor. Etiam
								mollis molestie justo. Sed aliquet ultrices
								metus, in pretium lacus egestas eget. Morbi
								convallis aliquam ante nec vulputate. In a
								volutpat massa, et interdum dolor. Duis
								consequat elit in risus volutpat, sit amet
								molestie ex accumsan. Suspendisse potenti.
								Praesent lorem odio, vehicula ultrices convallis
								in, convallis in mi. Duis vel mattis eros. Morbi
								eu urna dolor. Etiam mollis molestie justo. Sed
								aliquet ultrices metus, in pretium lacus egestas
								eget. Morbi convallis aliquam ante nec
								vulputate. In a volutpat massa, et interdum
								dolor. Duis consequat elit in risus volutpat,
								sit amet molestie ex accumsan.molestie ex
								accumsan.
							</p>
						</div>
						<Info
							published="March 17, 2021"
							updated="March 17, 2021"
						/>
						<div className={styles.aboutAuthor}>
							<AboutAuthor/>
						</div>
					</ContainerSmall>
				</div>
				<div className={styles.backTo}>
					<ButtonBack title="Back to blog posts" link="/blog"/>
				</div>
				<div className={styles.otherPosts}>
					<Container>
						<RelatedPost/>
					</Container>
				</div>
			</div>
		</Layout>
	);
};

export default BlogPost;
