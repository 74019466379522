import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import BlogItem from "../../blog/item/item";
import imgPost from "../../../assets/images/post.png";
import featurePostImg from "../../../assets/images/feature-post.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextArrow from "../../../components/slider-arrow/NextArrow";
import PrevArrow from "../../../components/slider-arrow/PrevArrow";
import styles from "./related-post.module.css";
import BlogList from "../../../components/blog-list/blog-list";
import ButtonLoadMore from "../../../components/button-load-more/button-load-more";

const RelatedPost = () => {
	const dataRelatedPost = [
		{
			categorie: "Other category",
			categorieSlug: "other-category",
			image: featurePostImg,
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			categorieSlug: "other-category",
			image: imgPost,
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			image: imgPost,
			categorieSlug: "other-category",
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			image: imgPost,
			categorieSlug: "other-category",
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			categorieSlug: "other-category",
			image: imgPost,
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			image: imgPost,
			categorieSlug: "other-category",
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
	];
	const [windownWidth, setWindowWidth] = useState(
		typeof window !== "undefined" ? window.innerWidth : 750
	);
	// const [loading, setLoading] = useState(false);
	useEffect(() => {
		function updateWindowWidth() {
			setWindowWidth(window.innerWidth);
		}

		window.addEventListener("resize", updateWindowWidth);
		updateWindowWidth();
		// setLoading(true);
		return () => window.removeEventListener("resize", updateWindowWidth);
	}, []);
	// const LIMIT_MOBILE = 3;
	// const [limit, setLimit] = useState(LIMIT_MOBILE);
	// const [posts, setPostsToShow] = useState(data);

	// useEffect(() => {
	// 	if (windownWidth <= 750) {
	// 		setPostsToShow(data.slice(0, limit));
	// 	}
	// }, [limit, data, windownWidth]);
	// const loadMore = () => {
	// 	setPostsToShow(data.slice(0, limit + LIMIT_MOBILE));
	// 	setLimit(limit + LIMIT_MOBILE);
	// };

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 2,
		nextArrow: <NextArrow/>,
		prevArrow: <PrevArrow/>,
	};

	return (
		<div>
			<h3 className={styles.title}>Releated posts</h3>
			{windownWidth > 750 ? (
				<div className={styles.wrapper}>
					<Slider {...settings}>
						{dataRelatedPost.map((data, index) => (
							<div className={styles.slider} key={index}>
								<BlogItem data={data}/>
							</div>
						))}
					</Slider>
				</div>
			) : (
				<>
					<BlogList dataPostList={dataRelatedPost}/>
					<div className={styles.btnLoadMore}>
						<ButtonLoadMore title="Load more realeted posts"/>
					</div>
				</>
			)}
		</div>
	);
};

export default RelatedPost;
