import React from "react";
import iconDmca from "../../assets/images/icon-dmca@2x.png";
import styles from "./footer.module.css";
import Social from "../social/social";
import Container from "../container/container";

const Footer = () => {
	return (
		<footer className={styles.footer}>
			<Container>
				<div className={styles.wrapper}>
					<div className={styles.left}>
						<a
							className={styles.link}
							href="mailto:team@goodwritings.com"
						>
							team@goodwritings.com
						</a>
					</div>
					<div className={styles.center}>
						<div className={styles.copyright}>
							<p>© 2021 GoodWritings.com</p>
						</div>

						<div>
							{!process.env.NODE_ENV ||
							process.env.NODE_ENV === "development" ? (
								<span className={styles.img}>
									<img
										src={iconDmca}
										alt="DMCA.com Protection Status"
									/>
								</span>
							) : (
								<a
									href="//www.dmca.com/Protection/Status.aspx?ID=e5277cc9-fab7-41d6-84fa-66b364984645"
									title="DMCA.com Protection Status"
									target="_blank"
									rel="noreferrer"
									className={styles.img}
								>
									<img
										src={iconDmca}
										alt="DMCA.com Protection Status"
									/>
								</a>
							)}
						</div>
					</div>
					<div className={styles.social}>
						<Social />
					</div>
				</div>
			</Container>
		</footer>
	);
};

export default Footer;
