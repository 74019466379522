import React from "react";
import styles from "./page-title-wrapper.module.css";
import Container from "../container/container";

const PageTitleWrapper = ({children, bgImage}) => {
	return (
		<div
			className={styles.wrapper}
			style={{backgroundImage: `url(${bgImage}`}}
		>
			<Container>{children}</Container>
		</div>
	);
};

export default PageTitleWrapper;
