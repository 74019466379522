import React from "react";
import styles from "./button-load-more.module.css";
import Arrow from "../arrow/arrow";

const ButtonLoadMore = ({title}) => {
	return (
		<button className={styles.btn}>
			<span>{title}</span>
			<Arrow arrow="down"/>
		</button>
	);
};
export default ButtonLoadMore;
