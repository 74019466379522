import React from "react";
import styles from "./slide-button.module.css";

const NextArrow = ({className, onClick}) => {
	return (
		<button
			className={
				` ${styles.button} ${styles.next} ` +
				(className.search("slick-disabled") !== -1 &&
					`${styles.disabled}`)
			}
			onClick={onClick}
		>
			<svg
				width="6"
				height="12"
				viewBox="0 0 6 12"
				xmlns="http://www.w3.org/2000/svg"
				fill="black"
			>
				<path
					d="M6 1.6989L2.25 5.94617L6 10.1934L5.25 11.8923L2.14569e-07 5.94617L5.25 -3.24894e-08L6 1.6989Z"/>
			</svg>
		</button>
	);
};

export default NextArrow;
