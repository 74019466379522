import React from "react";
import styles from "./button-back.module.css";
import Arrow from "../arrow/arrow";
import {Link} from "react-router-dom";

const ButtonBack = ({title, link}) => {
	return (
		<Link className={styles.btn} to={link}>
			<Arrow arrow="left"/>
			<span>{title}</span>
		</Link>
	);
};

export default ButtonBack;
