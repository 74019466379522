import React from "react";
import Layout from "../../components/layout/layout";
import Container from "../../components/container/container";
import styles from "./blog.module.css";
import imgPost from "../../assets/images/post.png";
import FeaturePost from "./feature-post/feature-post";
import featurePostImg from "../../assets/images/feature-post.png";
import ButtonLoadMore from "../../components/button-load-more/button-load-more";
import BlogList from "../../components/blog-list/blog-list";
import SEO from "../../components/seo/seo";
import PageTitleWrapper from "../../components/page-title-wrapper/page-title-wrapper";
import bgPage from "../../assets/images/bg-page.png";

const Blog = () => {
	const dataPostList = [
		{
			categorie: "Category",
			categorieSlug: "other-category",
			image: featurePostImg,
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			categorieSlug: "other-category",
			image: imgPost,
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			image: imgPost,
			categorieSlug: "other-category",
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer tang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			image: imgPost,
			categorieSlug: "other-category",
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity",
		},
	];
	return (
		<Layout page="blog">
			<SEO/>
			<section className={styles.detail}>
				<PageTitleWrapper bgImage={bgPage}>
					<h1 className={styles.title}>The latest from our team</h1>
					<div className={styles.caption}>
						Release notes, updates, annoucements, and more
					</div>
				</PageTitleWrapper>
				<div className={styles.bgColor}>
					<Container>
						<div className={styles.featurePost}>
							<FeaturePost data={dataPostList[0]}/>
						</div>
						<div className={styles.postList}>
							<BlogList dataPostList={dataPostList}/>
						</div>

						<div className={styles.btnLoadMore}>
							<ButtonLoadMore title="Load more articles"/>
						</div>
					</Container>
				</div>
			</section>
		</Layout>
	);
};

export default Blog;
