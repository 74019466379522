import React from "react";
import styles from "./blog-list.module.css";
import BlogItem from "../../pages/blog/item/item";

const BlogList = ({dataPostList}) => {
	return (
		<ul className={styles.list}>
			{dataPostList.map((data, index) => (
				<li key={index}>
					<BlogItem data={data}/>
				</li>
			))}
		</ul>
	);
};

export default BlogList;
