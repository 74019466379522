import React from "react";
import styles from "./post-info.module.css";
import PropTypes from "prop-types";

const PostInfo = ({published, updated}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.left}>
				<span className={styles.ttl}>Published</span>
				<span className={styles.date}>{published}</span>
			</div>
			<div className={styles.right}>
				<span className={styles.ttl}>Updated</span>
				<span className={styles.date}>{updated}</span>
			</div>
		</div>
	);
};

PostInfo.propTypes = {
	published: PropTypes.string,
	updated: PropTypes.string,
};

export default PostInfo;
