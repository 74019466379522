import React from "react";
import styles from "./social.module.css";
import iconFacebook from "../../assets/images/icon-facebook.svg";
import iconTwitter from "../../assets/images/icon-twitter.svg";

const Social = () => {
	const dataSocial = [
		{
			link: "https://www.facebook.com/Good-Writings-106402225039404",
			icon: iconFacebook,
			alt: "icon Facebook",
		},
		{
			link: "https://twitter.com/writings_good",
			icon: iconTwitter,
			alt: "icon Twitter",
		},
	];
	return (
		<ul className={styles.list}>
			{dataSocial.map((item, index) => (
				<li className={styles.item} key={index}>
					<a
						href={item.link}
						className={styles.link}
						target="_blank"
						rel="noreferrer"
					>
						<img src={item.icon} alt={item.alt} />
					</a>
				</li>
			))}
		</ul>
	);
};

export default Social;
