import React from "react";
import styles from "./slide-button.module.css";

const PrevArrow = ({currentSlide, slideCount, ...props}) => {
	return (
		<button
			{...props}
			className={
				` ${styles.button} ${styles.prev} ` +
				(currentSlide === 0 ? `${styles.disabled}` : "")
			}
			aria-hidden="true"
			aria-disabled={currentSlide === 0 ? true : false}
			type="button"
		>
			<svg
				width="6"
				height="12"
				viewBox="0 0 6 12"
				fill="black"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M6 1.6989L2.25 5.94617L6 10.1934L5.25 11.8923L2.14569e-07 5.94617L5.25 -3.24894e-08L6 1.6989Z"/>
			</svg>
		</button>
	);
};

export default PrevArrow;
