import React, {useState, useEffect} from "react";
import styles from "./header.module.css";
import Logo from "./logo/logo";
import Nav from "./nav/nav";
import Container from "../container/container";

const Header = ({page}) => {
	const [isSticky, setSticky] = useState(false);
	const handleScroll = () => {
		if (typeof window !== "undefined") {
			setSticky(window.pageYOffset);
		}
	};
	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", () => handleScroll);
		};
	}, []);
	return (
		<header
			className={
				styles.wrapper +
				` header-${page}` +
				`${isSticky ? " sticky" : ""}`
			}
		>
			<Container>
				<div className={styles.inner}>
					<Logo/>
					{/* <Nav /> */}
				</div>
			</Container>
		</header>
	);
};

export default Header;
