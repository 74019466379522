import React from "react";
import styles from "./logo.module.css";
import {Link} from "react-router-dom";

const Logo = () => {
	return (
		<Link className={styles.link + " header-logo"} to="/">
			GoodWritings
		</Link>
	);
};

export default Logo;
