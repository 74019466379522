import React from "react";
import styles from "./category.module.css";
import {Link} from "react-router-dom";

const Category = ({link, title}) => {
	return (
		<Link className={styles.name} to={link}>
			{title}
		</Link>
	);
};

export default Category;
